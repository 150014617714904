import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../../Pages/Home/Home';
import Catalog from '../../Pages/Catalog/Catalog';
import AboutUs from '../../Pages/AboutUs/AboutUs';
import Reviews from '../../Pages/Reviews/Reviews';
import Contacts from '../../Pages/Contacts/Contacts';
import Detail from '../../Pages/Detail/Detail';
import PreOrder from '../../Pages/PreOrder/PreOrder';
import { useAppDispatch } from '../../store/hooks';
import { fetchByCompanyInfo } from '../../store/slices/infoSlice';
import Products from '../../Pages/Products/Products';
import Favorites from '../../Pages/Favorites/Favorites';
import PreOrderProduct from '../../Pages/PreOrderProduct/PreOrderProduct';
import PrivacyPolicy from '../../Pages/PrivacyPolicy/PrivacyPolicy';

const Main = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByCompanyInfo())
    }, [dispatch])

    return (
        <main className='main'>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/catalog' element={<Catalog />} />
                <Route path='/catalog/products/:id/:name' element={<Products />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/reviews' element={<Reviews />} />
                <Route path='/contacts' element={<Contacts />} />
                <Route path='/favorites' element={<Favorites />} />
                <Route path='/detail-product/:id/:product_name' element={<Detail />} />
                <Route path='/pre-order' element={<PreOrder />} />
                <Route path='/detail-product/:id/:product_name/pre-order-product' element={<PreOrderProduct />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            </Routes>
        </main>
    );
};

export default Main;