import { configureStore } from '@reduxjs/toolkit'
import infoSlice from './slices/infoSlice'
import productsSlice from './slices/productsSlice'
import whishlistSlice from './slices/whishlistSlice'
import preOrderSlice from './slices/preOrderSlice'

export const store = configureStore({
    reducer: {
        info: infoSlice,
        products: productsSlice,
        whishlist: whishlistSlice,
        preOrder: preOrderSlice
    },
    devTools: false
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch