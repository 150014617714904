import React, { FC } from 'react'
import s from './Catalog.module.scss'
import ProductCard from '../../../components/ProductCard/ProductCard'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks'
import { ReactComponent as Long_arrow } from '../../../assets/icon/long_arrow.svg'

const Catalog: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <section>
            <div className={`${s.wrapper} container`}>
                <h2 className='title'>Что Мы Предлагаем?</h2>
                <div className={s.box}>
                    {
                        info?.what_we_offer_products.map((e) => (
                            <ProductCard key={e.id} data={e} />
                        ))
                    }

                </div>
                <div className={s.btn}>
                    <Link to='/catalog' className={`${s.button} button`}>СМОТРЕТЬ ВСЕ<Long_arrow /></Link>
                </div>
            </div>
        </section>
    )
}

export default Catalog