import React, { useState } from 'react';
import s from './BurgerMenu.module.scss';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/images/BIORO.png'
import { useAppSelector } from '../../store/hooks';

const BurgerMenu = () => {
    const [isClosed, setIsClosed] = useState(false);
    const { countInWhishlist } = useAppSelector(state => state.whishlist)

    const handleClick = () => {
        setIsClosed(!isClosed);
    };

    return (
        <div className={s.container}>
            <div className={s.header}>
                <Link onClick={() => setIsClosed(false)} className={s.logo} to='/'>
                    <img src={logo} alt="logo" />
                </Link>
                <button onClick={handleClick} className={s.button}>
                    <svg fill='#000' className={`${s.burger6} ${s.ham} ${s.hamRotate} ${s.ham1} ${isClosed ? s.isClosed : ''}`} viewBox="0 0 100 100">
                        <path
                            className={`${s.line} ${s.top}`}
                            d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
                        />
                        <path className={`${s.line} ${s.middle}`} d="m 30,50 h 40" />
                        <path
                            className={`${s.line} ${s.bottom}`}
                            d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
                        />
                    </svg>
                </button>
            </div>
            <div className={`${s.wrapper} ${isClosed && s.active}`}>
                <nav>
                    <ul>
                        <li><NavLink onClick={handleClick} to={'/catalog'} className={({ isActive }) => isActive ? s.active : ''}>Каталог</NavLink></li>
                        <li><NavLink onClick={handleClick} to="/about-us" className={({ isActive }) => isActive ? s.active : ''}> О нас</NavLink></li>
                        <li><NavLink onClick={handleClick} to={'/reviews'} className={({ isActive }) => isActive ? s.active : ''}>Отзывы</NavLink></li>
                        <li><NavLink to={'/favorites'}
                            onClick={handleClick}
                            data-count={countInWhishlist}
                            className={({ isActive }) => isActive ? `${s.active} ${s.whish}` : s.whish}>Избранное</NavLink></li>
                        <li><NavLink onClick={handleClick} to={'/pre-order'} className={({ isActive }) => isActive ? s.active : ''}>Предзаказ</NavLink></li>
                        <li><NavLink onClick={handleClick} to={'/contacts'} className={({ isActive }) => isActive ? s.active : ''}>Контакты</NavLink></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default BurgerMenu;
