import React, { FC, useEffect, useState } from 'react';
import s from './Courses.module.scss'
import localforage from 'localforage';
import rub from '../../../assets/icon/rub.svg'
import som from '../../../assets/icon/som.svg'

interface CoursesProps {
    price: string
}

const CACHE_KEY = 'exchange_rate_RUB_to_KGS';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 часа

const Courses: FC<CoursesProps> = ({ price }) => {
    const [showConverts, setShowConverts] = useState(false)
    const [rate, setRate] = useState<number | null>(null);
    const [amount, setAmount] = useState<number>(+price || 1);
    const [convertedAmount, setConvertedAmount] = useState<number | null>(null);

    useEffect(() => {
        const fetchRate = async () => {
            try {
                const cachedData = await localforage.getItem<{ rate: number, timestamp: number }>(CACHE_KEY);
                const now = Date.now();

                if (cachedData && now - cachedData.timestamp < CACHE_EXPIRY) {
                    setRate(cachedData.rate);
                } else {
                    const response = await fetch('https://v6.exchangerate-api.com/v6/79c36203b678461b9980cffc/latest/KGS');
                    const data = await response.json();
                    const rubRate = data.conversion_rates.RUB;
                    setRate(rubRate);
                    await localforage.setItem(CACHE_KEY, { rate: rubRate, timestamp: now });
                }
            } catch (error) {
                console.error('Error fetching exchange rate:', error);
            }
        };

        fetchRate();
    }, []);

    const handleConvert = () => {
        if (rate !== null) {
            setConvertedAmount(amount * rate);
        }
    };

    useEffect(() => {
        if (price) {
            setAmount(+price)
        }
    }, [price])

    return (
        <div className={s.wrapper}>
            <div className={s.images_wrapper}>
                <img src={rub} alt="RUB" />
                /
                <img src={som} alt="SOM" />
                <span onClick={() => setShowConverts(!showConverts)}>Курс валют - 1руб = {rate} сом</span>
            </div>
            {
                showConverts &&
                <div className={s.converts}>
                    <input
                        className={s.converts_input}
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(parseFloat(e.target.value))}
                        placeholder='Введите сумму KGS'
                    />
                    <button onClick={handleConvert}>Конвертировать</button>
                    {convertedAmount !== null && (
                        <div className={s.converted_wrapper}>
                            <p className={s.converted}>
                                {amount} KGS = {convertedAmount.toFixed(2)} RUB
                            </p>
                            <span>Курс взят с сайта
                                <a
                                    target='_blank'
                                    rel='noopener norefferer'
                                    href="https://www.exchangerate.com">
                                    exchangerate.com
                                </a>
                            </span>
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default Courses;