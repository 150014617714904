import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowRight } from './../../assets/icon/arrow_right.svg'
import s from './Button_link.module.scss'
interface ButtonProps {
    children: React.ReactNode,
    link: string
}

const ButtonLink: FC<ButtonProps> = ({ children, link }) => {
    return (
        <button className={s.btn}>
            <Link to={link}>{children}
                <ArrowRight width={20} color='#B59A6C' />
            </Link>
        </button>
    )
}

export default ButtonLink