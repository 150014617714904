import React, { FC } from 'react'
import s from './AboutIUs.module.scss'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks';
import { ReactComponent as Long_arrow } from '../../../assets/icon/long_arrow.svg'
import { Interweave } from 'interweave';
const AboutUS: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <section>
            <div className={`${s.wrapper} container`}>
                <div className={s.content_img}>
                    <div>
                        <img src={info?.about_us_image_1} alt={info?.about_us_image_1} />
                    </div>
                </div>
                <div className={s.content_title}>
                    <div >
                        <h2 className='title'>{info?.about_us_title}</h2>
                        <article>
                            <Interweave content={info?.about_us_description} />
                        </article>
                        <Link to={'/contacts'} className={`${s.button} button`}>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ <Long_arrow /></Link>
                    </div>
                    <img src={info?.about_us_image_2} alt={info?.about_us_image_2} />
                </div>
            </div>
        </section>
    )
}

export default AboutUS