import React, { ChangeEvent, DragEvent, FC, useEffect, useRef, useState } from 'react';
import s from './PreOrder.module.scss';
import { ReactComponent as Pictures } from './../../assets/icon/pictures.svg';
import { TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { submitPreOrder } from '../../store/slices/preOrderSlice';
import Loader from '../../components/Loader/Loader';
import Success from '../../components/Success/Success';
import { scrollToTop } from '../../utils';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Long_arrow } from './../../assets/icon/long_arrow.svg';

interface DataValue {
    textile: string
    price_per_unit: string;
    size_range: string;
    desired_colors: string;
    quantity: string;
    desired_delivery_date: string;
    technical_requirements: string;
    additional_questions: string;
    name: string;
    contact_method: string;
}

interface MyFormProps {
    data: DataValue;
    handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface dataValue {
    textile: string
    price_per_unit: string;
    size_range: string;
    desired_colors: string;
    quantity: string;
    desired_delivery_date: string;
    technical_requirements: string;
    additional_questions: string;
    name: string;
    contact_method: string;
    photo: File | null;
}

const PreOrder: FC = () => {
    const { loading } = useAppSelector(state => state.preOrder);
    const dispatch = useAppDispatch();
    const [success, setSuccess] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const errorRef = useRef<HTMLParagraphElement | null>(null);
    const [data, setData] = useState<dataValue>({
        textile: '',
        price_per_unit: '',
        size_range: '',
        desired_colors: '',
        quantity: '',
        desired_delivery_date: '',
        technical_requirements: '',
        additional_questions: '',
        name: '',
        contact_method: '',
        photo: null,
    });

    useEffect(() => {
        scrollToTop();
    }, []);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > 20 * 1024 * 1024) {
                setErrorMessage('Изображение превышает допустимый размер 20МБ.');
                setData(prevData => ({ ...prevData, photo: null }));
            } else {
                setErrorMessage(null);
                setData(prevData => ({
                    ...prevData,
                    photo: file,
                }));
            }
        }
    };

    const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (file.size > 20 * 1024 * 1024) {
                setErrorMessage('Изображение превышает допустимый размер 20МБ.');
                setData(prevData => ({ ...prevData, photo: null }));
            } else {
                setErrorMessage(null);
                setData(prevData => ({
                    ...prevData,
                    photo: file,
                }));
            }
        }
    };

    const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const imageUrl = data.photo ? URL.createObjectURL(data.photo) : '';

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (errorMessage) {
            const offsetTop = errorRef.current?.getBoundingClientRect().top || 0;
            const offsetPosition = window.pageYOffset + offsetTop - 400;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
            return;
        }
        try {
            await dispatch(submitPreOrder(data)).unwrap();
            setSuccess(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fields = [
        { id: 'textile', label: 'Желаемая ткань', required: true },
        { id: 'price_per_unit', label: 'Проходная цена за единицу', required: true },
        { id: 'size_range', label: 'Размерный ряд', required: true },
        { id: 'desired_colors', label: 'Желамые цвета', required: true },
        { id: 'quantity', label: 'Количество', required: true },
        { id: 'desired_delivery_date', label: 'Желаемый срок доставки товаров', required: true },
        { id: 'technical_requirements', label: 'ТЗ либо регламент', required: true, multiline: true, rows: 4 },
        { id: 'additional_questions', label: 'Есть ли дополнительные вопросы?', required: true, multiline: true, rows: 4 },
        { id: 'name', label: 'Ваше имя (обязательно)', required: true, inputProps: { maxLength: 100 } },
        { id: 'contact_method', label: 'Способ связаться с вами (обязательно)', required: true, inputProps: { maxLength: 100 } },
    ];

    if (success) {
        return <Success />;
    }

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Предзаказ | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Предзаказ | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/pre-order`} />
                <title>
                    Предзаказ | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            <section className={s.wrapper}>
                <div className={`${s.container} container`}>
                    <h2 className='title'>Закажите свой Бренд</h2>
                    <article>BIORO создаст вам ваш уникальный бренд. Заполните заявку и с вами в ближайшее время свяжется менеджер.</article>
                    <form onSubmit={handleSubmit}>
                        <label className={`${s.upload_button} ${isDragging ? s.dragging : ''}`}
                            style={{ borderColor: errorMessage ? 'red' : '' }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                        >
                            {data.photo ? (
                                <img src={imageUrl} alt="Preview" className={s.preview_image} />
                            ) : (
                                <i><Pictures /></i>
                            )}
                            <span>{data.photo ? "Заменить" : "Изображение"}</span>
                            {!data.photo && <span>Загрузите фото с вашими идеями</span>}
                            <input
                                required
                                type="file"
                                accept="image/*"
                                className={s.addPictures}
                                onChange={handleFileChange}
                            />
                        </label>
                        {errorMessage && <p ref={errorRef} className={s.errorMessage}>{errorMessage}</p>}
                        {fields.map((field) => (
                            <TextField
                                key={field.id}
                                className={s.inputField}
                                required={field.required}
                                id={field.id}
                                label={field.label}
                                variant="outlined"
                                value={data[field.id as keyof DataValue]}
                                onChange={handleChange}
                                multiline={field.multiline || false}
                                rows={field.rows || 1}
                                inputProps={field.inputProps || {}}
                            />
                        ))}
                        <button
                            type="submit"
                            className={`${s.button} button`}
                            title={errorMessage || ''}
                        >
                            ОТПРАВИТЬ ЗАЯВКУ<Long_arrow />
                        </button>
                    </form>
                </div>
            </section>
        </>
    );
};

export default PreOrder;
