import React, { FC } from 'react';
import './Loader.css'

const Loader: FC = () => {
    return (
        <div className='loader_wrapper'>
            <div className="newtons-cradle">
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
                <div className="newtons-cradle__dot"></div>
            </div>
        </div>
    );
};

export default Loader;