import React, { FC, MouseEventHandler } from 'react';
import s from './ProductCard.module.scss';
import { ReactComponent as Heart } from './../../assets/icon/heart.svg';
import { ReactComponent as Close } from './../../assets/icon/close.svg';
import { Link } from 'react-router-dom';
import { IProduct } from '../../store/types';
import { getLS, setLS } from '../../LS';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addToWhishlistReducer } from '../../store/slices/whishlistSlice';

interface CardProps {
    data: IProduct
}

const ProductCard: FC<CardProps> = ({ data }) => {
    const { whishlist } = useAppSelector(state => state.whishlist)


    const handleActive: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        addToWhishlist()
    };
    const dispatch = useAppDispatch()


    const addToWhishlist = () => {
        let all_products: IProduct[] = getLS('whishlist') || []
        const product = data
        if (all_products) {
            let one_elem = all_products.find(elem => elem.id === data.id)
            if (one_elem) {
                all_products = all_products.filter(el => el.id !== one_elem?.id)
            } else {
                all_products.push(product)
            }
            setLS('whishlist', all_products)
            dispatch(addToWhishlistReducer(all_products))
        }
    }

    return (
        <Link className={s.card} to={`/detail-product/${data?.id}/${data?.title}`}>
            <button
                title={whishlist?.find(el => el.id === data.id) ? 'Убрать из Избранное' : 'Добавить в избранное'}
                className={s.favorite} onClick={handleActive}>
                {whishlist?.find(el => el.id === data.id) ? <Close /> : <Heart />}
            </button>
            {data.discount && <span className={s.discounts}>{data.discount.includes('%') ? data.discount : `${data.discount}%`}</span>}
            <div className={s.box_image}>
                <img src={data.image} alt={data.title + "img_product"} />
                <span className={`${s.btn} button`}>СМОТРЕТЬ ДЕТАЛИ</span>
            </div>
            <h4>{data.title}</h4>
            <span>{Math.ceil(+data.price)} с</span>
        </Link>
    );
};

export default ProductCard;
