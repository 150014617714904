import React, { FC, useEffect } from 'react'
import s from './PrivacyPolicy.module.scss'
import { scrollToTop } from '../../utils'
import { useAppSelector } from '../../store/hooks'
import Loader from '../../components/Loader/Loader'
import { Interweave } from 'interweave';


const PrivacyPolicy: FC = () => {
    const { loading, info } = useAppSelector(state => state.info)
    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            {loading && <Loader />}
            <section className={`${s.wrapper} container`}>
                <h1 className='title'>Политика конфиденциальности</h1>
                <div >
                    <Interweave content={info?.privacy_policy} />
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy