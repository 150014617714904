import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { INewPreOrder, IPreOrderProduct } from '../types';
import { storesApi } from '../../axios';

type preOrderState = {
    error: null | string
    loading: boolean
}


const initialState: preOrderState = {
    error: null,
    loading: false,
};

export const submitPreOrder = createAsyncThunk<INewPreOrder, INewPreOrder, { rejectValue: string }>(
    'preOrder/submitPreOrder',
    async (data, { rejectWithValue }) => {
        try {
            const res = await storesApi.setNewPreOrder(data);
            if (res.status !== 201) {
                return rejectWithValue('Server Error');
            }

            return res.data as INewPreOrder;
        } catch (error) {
            console.error(error);
            return rejectWithValue('Server Error');
        }
    }
);


export const submitPreOrderProduct = createAsyncThunk<IPreOrderProduct, IPreOrderProduct, { rejectValue: string }>(
    'preOrder/submitPreOrderProduct',
    async (data, { rejectWithValue }) => {
        try {
            const res = await storesApi.setNewPreOrderProduct(data);
            if (res.status !== 201) {
                return rejectWithValue('Server Error');
            }

            return res.data as IPreOrderProduct;
        } catch (error) {
            console.error(error);
            return rejectWithValue('Server Error');
        }
    }
);


const preOrderSlice = createSlice({
    name: 'preOrder',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitPreOrder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitPreOrder.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(submitPreOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = 'Ошибка сервера!'
            })
            // =================================================
            .addCase(submitPreOrderProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(submitPreOrderProduct.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(submitPreOrderProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = 'Ошибка сервера!'
            })
    },
});

export default preOrderSlice.reducer;
