import React, { FC, useState, useRef } from "react";
import s from "./Accordion.module.scss";
import { ReactComponent as Plus } from './../../assets/icon/tabler-icon-plus.svg';
import { ReactComponent as Minus } from './../../assets/icon/tabler-icon-minus.svg';
import { ReactComponent as Minus_gold } from './../../assets/icon/minus_gold.svg';
import { ReactComponent as Plus_gold } from './../../assets/icon/plus_gold.svg';
import { Interweave } from 'interweave';
interface AccordionProps {
  title: string;
  description: string;
  additionalStyles?: string
}

const Accordion: FC<AccordionProps> = ({ additionalStyles, title, description }) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [animation, setAnimation] = useState('');

  const handlerOpen = () => {
    setIsOpen(!isOpen);
    setAnimation(isOpen ? 'animate__animated animate__fadeOutUp' : 'animate__animated animate__fadeInDown');
  };

  return (
    <div className={`${s.accordion_item} ${additionalStyles}`}>
      <button onClick={handlerOpen} className={s.accordion_header}>
        {title}
        {isOpen ? additionalStyles ? <Minus_gold /> : <Minus /> : additionalStyles ? <Plus_gold /> : <Plus />}
      </button>
      <div
        className={s.accordion_collapse}
        style={
          isOpen && itemRef.current
            ? { height: itemRef.current.scrollHeight + "px", marginBottom: 15 }
            : { height: '0px', marginBottom: 0 }
        }>
        <div ref={itemRef} className={`${s.accordion_body} ${animation}`} >
          <Interweave content={description} />
        </div>
      </div>
    </div>
  );
};

export default Accordion;
