import React, { FC, useEffect } from 'react';
import s from './AboutUs.module.scss'
import { Link } from 'react-router-dom';
import Collection_Swiper from '../../components/Collection_Swiper/Collection_Swiper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { scrollToTop } from '../../utils';
import { fetchByFaqs } from '../../store/slices/infoSlice';
import Accordion from '../../components/Accordion/Accordion';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Long_arrow } from './../../assets/icon/long_arrow.svg'
import { Interweave } from 'interweave';

const AboutUs: FC = () => {
    const { info, faqs, loading } = useAppSelector(state => state.info)
    // console.log(info);


    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchByFaqs())
        scrollToTop()
    }, [])
    return (
        <>
            <Helmet>
                <meta property="og:title" content={`О нас | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`О нас | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/about-us`} />
                <title>
                    О нас | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            <article className={`${s.about_us} container`}>
                <img src={info?.about_us_page_image} alt="Иллюстративное фото страницы О нас | Bioro" />
                <div className={s.text_wrapper}>
                    <h1 className={`${s.title} title`}>О нас</h1>
                    <Interweave className={s.text} content={info?.about_us_page_description} />
                </div>
            </article>
            <section>
                <div className={`${s.wrapper} container`} style={{ marginBottom: '50px' }}>
                    <div className={s.questions}>
                        <h2 className='title' style={{ textAlign: 'center' }}>Частые вопросы и ответы</h2>
                        {
                            faqs && faqs?.length > 0 &&
                            faqs?.map(el => (
                                <Accordion additionalStyles={s.faqs} key={el.id} title={el.title} description={el.description} />
                            ))
                        }
                    </div>
                    {info && <Collection_Swiper title={'Коллекции Bioro'} data={info.catalogs} />}
                    <div className={s.btn}>
                        <Link to={'/catalog'} className={`button`}>СМОТРЕТЬ ВСЕ <Long_arrow /></Link>
                    </div>
                </div>
            </section>
            <section className={`${s.feedback} container`}>
                <h2 className={s.title}>Вместе Мы Добьемся Большего!</h2>
                <Link to={'/contacts'} className={`${s.feedback_btn} button`}>СВЯЗАТЬСЯ С НАМИ<Long_arrow /></Link>
            </section>
        </>
    );
};

export default AboutUs;