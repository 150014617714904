import React, { useEffect } from 'react';
import Hero from './Hero/Hero';
import Offers from './Offers/Offers';
import AboutUS from './AboutUs/AboutUS';
import Catalog from './Catalog/Catalog';
import Collection from './Collection/Collection';
import Reviews from './Reviews/Reviews';
import ContactForm from './ContactForm/ContactForm';
import { scrollToTop } from '../../utils';
import { useAppSelector } from '../../store/hooks';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    const { loading } = useAppSelector(state => state.info)

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <Helmet>
                <meta property="og:title" content={`Главная | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Главная | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/`} />
                <title>
                    Главная | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            < Hero />
            <Offers />
            <AboutUS />
            <Catalog />
            <Collection />
            <Reviews />
            <ContactForm />
        </>
    );
};

export default Home;