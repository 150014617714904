import React from 'react'
import s from './ContactForm.module.scss'
import contact_form from '../../../assets/images/home/contact_form.jpg'
import CallbackForm from '../../../components/CallbackForm/CallbackForm'

const ContactForm = () => {
    return (
        <section className={s.section}>
            <div className={`${s.wrapper} container`}>
                <div className={s.content_title}>
                    <h2 className='title'>Хотите Получить Консультацию?</h2>
                    <img className={s.img} src={contact_form} alt="img" />
                </div>
                <div className={s.box_form}>
                    <p>Заполните форму и мы свяжемся с Вами в ближайшее время:</p>
                    <CallbackForm />
                    <img className={s.img} src={contact_form} alt="img" />
                </div>

            </div>
        </section>
    )
}

export default ContactForm