import React, { FC } from 'react';
import s from './Hero.module.scss'
import ButtonLink from '../../../components/Button_link/Button_link';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { ReactComponent as Long_arrow } from '../../../assets/icon/long_arrow.svg'
import { Interweave } from 'interweave';

const Hero: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <section className={s.section}>
            <div className={`${s.wrapper} container`}>
                <div className={s.content_title}>
                    <h1 className='title'>{info?.hero_title}</h1>
                    <Interweave content={info?.hero_description} />
                    <Link to={'/pre-order'} className={`${s.button} button`}>ОФОРМИТЬ ПРЕДЗАКАЗ <Long_arrow /></Link>
                </div>
                <div className={s.content_image}>
                    <div>
                        <img src={info?.hero_image1} alt={`${info?.hero_image1}`} />
                    </div>
                    <div>
                        <img src={info?.hero_image2} alt={`${info?.hero_image2}`} />
                        <ButtonLink link={'/catalog'}>Перейти в Каталог</ButtonLink>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Hero