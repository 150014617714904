import React, { FC } from 'react';
import s from './Collection_Swiper.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { ReactComponent as ArrowIcon } from './../../assets/icon/arrow_right.svg';
import './Collection_Swiper.scss';
import CatalogCard from '../CatalogCard/CatalogCard';
import { ICatalogCard } from '../../store/types';

interface Collection_SwiperProps {
    title: string;
    data: ICatalogCard[]
}

const Collection_Swiper: FC<Collection_SwiperProps> = ({ data, title }) => {
    return (
        <>
            <div className={s.header}>
                <h2 className='title'>{title}</h2>
                <div className={s.customs}>
                    <div className={`${s.customButtonPrev} review-swiper-button-prev`}><ArrowIcon color='#B59A6C' /></div>
                    <div className={`${s.customPagination} swiper-pagination`}></div>
                    <div className={`${s.customButtonNext} review-swiper-button-next`}><ArrowIcon color='#B59A6C' /></div>
                </div>
            </div>
            <div className={s.box}>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    slidesPerGroup={4}
                    pagination={{
                        type: 'fraction',
                        el: `.swiper-pagination`,
                        bulletClass: s.paginationBullet,
                        bulletActiveClass: s.paginationBulletActive,
                        formatFractionCurrent: (number: number) => number < 10 ? '0' + number : number,
                        formatFractionTotal: (number: number) => number < 10 ? '0' + number : number,
                    }}
                    navigation={{
                        nextEl: ".review-swiper-button-next",
                        prevEl: ".review-swiper-button-prev",
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.1,
                            slidesPerGroup: 1,
                            spaceBetween: 10,
                        },
                        960: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 30,
                        },
                    }}
                    modules={[Pagination, Navigation]}
                >
                    {data.map((e, index) => (
                        <SwiperSlide key={index} className={s.sliders}>
                            <CatalogCard key={index} data={e} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
}

export default Collection_Swiper;
