import React, { FC, useEffect, useState } from 'react';
import s from './PreOrderProduct.module.scss';
import { useParams } from 'react-router-dom';
import QuantityTable from './QuantityTable/QuantityTable';
import PlacingOrder from './PlacingOrder/PlacingOrder';
import Success from '../../components/Success/Success';
import { scrollToTop } from '../../utils';
import { Helmet } from 'react-helmet-async';

export interface Size {
    id: number;
    size: number;
    quantity: number;
}
export interface Row {
    id: number;
    sizes: Size[];
    color: string;
    color_quantity: number;
    color_id: number | null;
}

export interface StateProps {
    name: string
    contact_method: string
    product_id: number | undefined;
    total_quantity: number;
    rows: Row[];
    image: undefined | string;
    title: null | string;
    product_price: number | null;
}

const PreOrderProduct: FC = () => {
    const { id, product_name } = useParams()
    const [success, setSuccess] = useState<boolean>(false);
    const [state, setState] = useState<StateProps>({
        name: '',
        contact_method: '',
        product_id: 0,
        image: '',
        title: '',
        product_price: 0,
        total_quantity: 0,
        rows: []
    });

    const [switchComponents, setSwitchComponents] = useState(false);

    const toggleSwitchComponents = () => {
        setSwitchComponents(prev => !prev);
    };

    useEffect(() => {
        scrollToTop()
        const product = sessionStorage.getItem('product')
        if (product) {
            setState(JSON.parse(product))
        }

    }, [])

    useEffect(() => {
        if (switchComponents) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                setSwitchComponents(false)
            };
        } else {
            window.history.replaceState(null, "", window.location.href);
        }
        return () => {
            window.onpopstate = () => { };
        };
    }, [switchComponents]);

    if (success) {
        return <Success />;
    }

    return (
        <section className={s.wrapper}>
            <Helmet>
                <meta property="og:title" content={`Предзаказ ${product_name} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Предзаказ ${product_name} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/detail-product/${id}/${product_name}/pre-order-product`} />
                <title>
                    Предзаказ {product_name} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            <div className={`${s.container} container`}>
                <h1 className='title'>Предзаказ {state?.title}</h1>
                <p className={s.text}>Вы оформляете заявку на предзаказ изделия "{state?.title}", пожалуйста выберите цвета и размеры. Уведомляем о том, что минимальный заказ для одного цвета - 300 единиц. Минимальный общий заказ - 500 единиц суммарно.</p>
                {switchComponents ? (
                    <PlacingOrder setSuccess={setSuccess} setState={setState} state={state} toggleSwitchComponents={toggleSwitchComponents} />
                ) : (
                    <QuantityTable setState={setState} state={state} toggleSwitchComponents={toggleSwitchComponents} />
                )}
            </div>
        </section>
    );
};

export default PreOrderProduct;
