import React, { FC } from 'react'
import s from './Offers.module.scss'
import { ReactComponent as Crystal } from './../../../assets/icon/crystal.svg'
import { ReactComponent as Bell } from './../../../assets/icon/bell.svg'
import { ReactComponent as Lock } from './../../../assets/icon/lock.svg'
import { ReactComponent as Star } from './../../../assets/icon/star.svg'

const Offers: FC = () => {
    return (
        <section>
            <div className={`${s.wrapper} container`}>
                <div className={s.card}>
                    <div><Crystal /></div>
                    <div>
                        <p>Уникальный дизайн</p>
                        <p>Создание стильных коллекций по сезону</p>
                    </div>
                </div>
                <div className={s.card}>
                    <div><Bell /></div>
                    <div>
                        <p>Превосходный сервис</p>
                        <p>Оперативная обратная связь и поддержка</p>
                    </div>
                </div>
                <div className={s.card}>
                    <div><Lock /></div>
                    <div>
                        <p>Качество и мастерство</p>
                        <p>Высокое качество работы и опыт мастеров</p>
                    </div>
                </div>
                <div className={s.card}>
                    <div><Star /></div>
                    <div>
                        <p>Индивидуальный подход</p>
                        <p>Адаптация под предпочтения клиента</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Offers