import React, { FC, SetStateAction, useEffect } from 'react';
import s from './QuantityTable.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchByColors } from '../../../store/slices/infoSlice';
import { MenuItem, Select } from '@mui/material';
import { Size, StateProps } from '../PreOrderProduct';
import { ReactComponent as ClueIcon } from './../../../assets/icon/clue.svg'
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Long_arrow } from './../../../assets/icon/long_arrow.svg'

interface QuantityTableProps {
    setState: React.Dispatch<React.SetStateAction<StateProps>>;
    toggleSwitchComponents: () => void;
    state: StateProps;
}

const QuantityTable: FC<QuantityTableProps> = ({ setState, state, toggleSwitchComponents }) => {

    const { loading, colors } = useAppSelector(state => state.info);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchByColors());
    }, [dispatch]);


    const generateUniqueId = () => Date.now() + Math.random();
    const addRow = () => {
        setState(prevState => ({
            ...prevState,
            rows: [...prevState.rows, { id: generateUniqueId(), sizes: [], color: '', color_id: null, color_quantity: 0 }]
        }));
    };

    const handleSelect = (rowId: number, color: string, colorId: number | null) => {
        setState(prevState => ({
            ...prevState,
            rows: prevState.rows.map(row =>
                row.id === rowId ? { ...row, color, color_id: colorId } : row
            )
        }));
    };

    const handleSizeQuantityChange = (rowId: number, sizeId: number, quantity: string) => {
        const quantityNum = Number(quantity);
        setState(prevState => {
            const updatedRows = prevState.rows.map(row => {
                if (row.id === rowId) {
                    const updatedSizes = row.sizes.some(size => size.id === sizeId)
                        ? row.sizes.map(size =>
                            size.id === sizeId
                                ? { ...size, quantity: quantityNum || 0 }
                                : size
                        )
                        : [...row.sizes, { id: sizeId, size: sizeId, quantity: quantityNum || 0 }];

                    const quantityRow = calculateRowTotal(updatedSizes);

                    return {
                        ...row,
                        sizes: updatedSizes,
                        color_quantity: quantityRow,
                    };
                }
                return row;
            });

            const totalQuantity = updatedRows.reduce((total, row) => total + row.color_quantity, 0);

            return {
                ...prevState,
                rows: updatedRows,
                total_quantity: totalQuantity
            };
        });
    };

    const calculateRowTotal = (sizes: Size[]) => {
        return sizes.reduce((total, size) => total + size.quantity, 0);
    };

    const isButtonDisabled = () => {
        const hasIncompleteRows = state.rows.some(row => row.color_id === null || row.color_quantity < 300);
        return hasIncompleteRows || state.total_quantity < 500;
    };


    const removeRow = (rowId: number) => {
        setState(prevState => {
            const updatedRows = prevState.rows.filter(row => row.id !== rowId);
            const totalQuantity = updatedRows.reduce((total, row) => total + row.color_quantity, 0);
            return {
                ...prevState,
                rows: updatedRows,
                total_quantity: totalQuantity
            };
        });
    };

    return (
        <div className={s.table_box}>
            <div className={s.table}>
                <ul className={`${s.row} ${s.size}`}>
                    <li>42</li>
                    <li>44</li>
                    <li>46</li>
                    <li>48</li>
                    <li>50</li>
                    <li>52</li>
                    <li>54</li>
                    <li>56</li>
                    <li>Цвет</li>
                    <li className={s.quantity_row}>{window.innerWidth <= 768 ? 'Кол.' : 'Количество'}</li>
                </ul>
                {state.rows.map((row, index) => (
                    <ul key={index} className={s.row}>
                        {[42, 44, 46, 48, 50, 52, 54, 56].map((size, index) => (
                            <li key={index} className={s.cell}>
                                <input
                                    maxLength={3}
                                    type="text"
                                    value={row.sizes.find(s => s.size === size)?.quantity || ''}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            handleSizeQuantityChange(row.id, size, value);
                                        }
                                    }}
                                />
                                <ClueIcon className={s.clueIcon} />
                                <span className={s.clue}>Впишите нужное количество товара</span>
                            </li>
                        ))}
                        <li>
                            <Select
                                className={s.chooseColor}
                                value={row.color}
                                onChange={(e) => {
                                    const colorId = colors?.find(color => color.color === e.target.value)?.id ?? null;
                                    handleSelect(row.id, e.target.value, colorId);
                                }}
                            >
                                {colors?.map((color) => (
                                    <MenuItem key={color.id} value={color.color}>
                                        <span
                                            style={{ backgroundColor: color.color }}
                                            className={s.colorCircle}
                                        ></span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </li>
                        <li className={s.quantity_row}>
                            <input disabled maxLength={3} type="text" value={row.color_quantity || 0} />
                        </li>
                        <button className={s.deleteRow} onClick={() => removeRow(row.id)}><CloseIcon /></button>
                    </ul>
                ))}
                {window.innerWidth >= 768 && <button onClick={addRow} className={s.addRow}>+</button>}
                {window.innerWidth >= 768 &&
                    <ul className={`${s.row} ${s.total}`}>
                        <li>Всего:</li>
                        <li className={s.quantity_row}>{state.total_quantity}</li>
                    </ul>}
            </div>

            {window.innerWidth <= 768 &&
                <ul className={s.totalQuantity}>
                    <li>Всего:</li>
                    <li className={s.quantity_row}>{state.total_quantity}</li>
                </ul>}
            {window.innerWidth <= 768 && <button onClick={addRow} className={s.addRow}>+</button>}

            <button
                className={`${s.button} button ${!isButtonDisabled() ? s.button_active : ''}`}
                disabled={isButtonDisabled()}
                onClick={toggleSwitchComponents}
            >
                <span className={s.clue}>Минимальный заказ для одного цвета - 300 единиц. Общее количество товаров - 500 единиц суммарно.</span>
                ДАЛЕЕ
                <Long_arrow />
            </button>
        </div>
    );
};

export default QuantityTable;




