import React, { FC, useEffect } from 'react';
import s from './Favorites.module.scss'
import { useAppSelector } from '../../store/hooks';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Helmet } from 'react-helmet-async';
import { scrollToTop } from '../../utils';

const Favorites: FC = () => {
    const { whishlist } = useAppSelector(state => state.whishlist)

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <div className='container'>
            <Helmet>
                <meta property="og:title" content={`Избранное | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Избранное | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/favorites`} />
                <title>
                    Избранное | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            <h1 className={`title ${s.title}`}>Избранное</h1>
            <div className={s.wrapper}>
                {
                    whishlist.length > 0 ?
                        whishlist.map(el => <ProductCard key={el.id} data={el} />)
                        :
                        <h2 className={s.empty}>У вас пока нет избранных.</h2>
                }
            </div>
        </div>
    );
};

export default Favorites;