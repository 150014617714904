import React, { useEffect } from 'react';
import s from './Contacts.module.scss'
import CallbackForm from '../../components/CallbackForm/CallbackForm';
import { useAppSelector } from '../../store/hooks';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const Contacts = () => {
    const { info, loading } = useAppSelector(state => state.info)

    useEffect(() => {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])

    return (
        <div className={s.wrapper}>
            <Helmet>
                <meta property="og:title" content={`Контакты | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Контакты | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/contacts`} />
                <title>
                    Контакты | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            <div className={s.container}>
                <div className={s.contact_block}>
                    <div className={s.contacts}>
                        <h2>Контакты</h2>
                        <p>Номер телефона: <a href={`tel:${info?.contacts.phone}`}>{info?.contacts.phone}</a></p>
                        <p>Почта: <a href={`mailto:${info?.contacts.email}`}>{info?.contacts.email}</a></p>
                    </div>
                    <div className={s.address}>
                        <h2>Адрес</h2>
                        <p>Адрес: <a target='_blank' rel={'noopener noreferrer'} href={`https://2gis.kg/bishkek/search/${info?.contacts.address}`}>{info?.contacts.address}</a></p>
                        <p>График работы: ПН-ВС, с 9.00 до 18.00</p>
                    </div>
                </div>
                <div className={s.callback_block}>
                    <h2>Бесплатная Консультация</h2>
                    <CallbackForm />
                </div>
            </div>
        </div>
    );
};

export default Contacts;