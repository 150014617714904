import React, { FC } from 'react';
import s from './CatalogCard.module.scss'
import { ICatalogCard } from '../../store/types';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/icon/arrow_right.svg'

interface CatalogCardProps {
    data: ICatalogCard
}

const CatalogCard: FC<CatalogCardProps> = ({ data }) => {
    const { id, image, title } = data
    return (
        <div className={s.card}>
            <Link to={`/catalog/products/${id}/${encodeURIComponent(title)}`} className={s.inner_link}>
                <div className={s.inner_card}>
                    <img className={s.card_img} src={image} alt={title} />
                    <h3 className={s.title}>{title}</h3>
                    <span className={s.link_btn}>
                        <button className={s.btn}>
                            Смотреть коллекцию
                            <ArrowRight width={20} color='#B59A6C' />
                        </button>
                    </span>
                </div>
            </Link>
        </div>
    );
};

export default CatalogCard;