import React, { FC, useEffect, useRef, useState } from 'react';
import s from './CallbackForm.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { submitFeedback, toggleCaptcha, validateCaptcha } from '../../store/slices/infoSlice';
import { TextField } from '@mui/material';
import Loader from '../Loader/Loader';
import SuccessModal from './SuccessModal/SuccessModal';
import { ReactComponent as Long_arrow } from './../../assets/icon/long_arrow.svg'


const CallbackForm: FC = () => {
    const dispatch = useAppDispatch()
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const { feedback_loading, feedback_error, captcha, feedback_success } = useAppSelector(state => state.info)
    const [tokenErr, setTokenErr] = useState<string | null>(null)
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        order_details: ''
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (captcha) {
            dispatch(submitFeedback(formData))
            setFormData({
                name: '',
                email: '',
                order_details: ''
            });
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
        } else {
            setTokenErr('Вы не прошли капчу!')
        }
    };

    useEffect(() => {
        const handleResize = () => setInnerWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])


    const confirmationReCAPTCHA = (token: any) => {
        if (token) {
            dispatch(validateCaptcha(token))
        } else {
            dispatch(toggleCaptcha())
        }
    }

    return (
        <>
            {feedback_loading && <Loader />}
            <form className={s.form} onSubmit={handleSubmit}>
                <TextField
                    className={s.inputField}
                    required
                    id="name"
                    label="Имя"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                />
                <TextField
                    className={s.inputField}
                    required
                    type="email"
                    id="email"
                    label="Почта"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                />
                <TextField
                    className={s.inputField}
                    required
                    id="order_details"
                    label={innerWidth < 767 ? 'Сообщение' : 'Какие модели и в каком количестве желаете заказать?'}
                    variant="outlined"
                    value={formData.order_details}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                />
                <ReCAPTCHA
                    ref={recaptchaRef}
                    className={s.boxReCaptcha}
                    sitekey="6LdcnhoqAAAAAFdI3eznYJXC5VEdAs5x2Rus7ybQ"
                    onChange={confirmationReCAPTCHA}
                />
                {tokenErr && <span className='error'>{tokenErr}</span>}
                {feedback_error && <span className='error'>{feedback_error}</span>}
                <button disabled={!captcha}
                    type="submit" className={`${s.button} button`}>ОТПРАВИТЬ ЗАЯВКУ <Long_arrow /></button>
            </form>
            {feedback_success && <SuccessModal />}
        </>
    );
};

export default CallbackForm;
