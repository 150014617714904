import axios from 'axios'
import { INewFeedback, INewPreOrder, IPreOrderProduct, IProductsByCatalog } from '../store/types'


const instanse = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
})

export const storesApi = {
    getMainAndInfoCompany() {
        return instanse.get('main')
    },
    getReviews() {
        return instanse.get('reviews')
    },
    getCatalog() {
        return instanse.get('catalog')
    },
    getProductsByCatalog({ id, page }: IProductsByCatalog) {
        return instanse.get(`products?catalog_id=${id}&page=${page}`)
    },
    getProductById(id: number) {
        return instanse.get(`products/${id}`)
    },
    validateCaptcha(captcha_token: string) {
        return instanse.post(`validate-captcha`, { captcha_token })
    },
    setNewFeedBack(data: INewFeedback) {
        return instanse.post(`feedback`, data)
    },
    getFaqs() {
        return instanse.get('faqs')
    },
    getColors() {
        return instanse.get('colors')
    },
    setNewPreOrder(data: INewPreOrder) {
        return instanse.post('request', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    setNewPreOrderProduct(data: IPreOrderProduct) {
        return instanse.post('preorder', data)
    }

}
