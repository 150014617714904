import React, { FC, useEffect, useState } from 'react';
import s from './Catalog.module.scss'
import catalog1 from '../../assets/images/catalog/catalog1.png'
import catalog2 from '../../assets/images/catalog/catalog2.png'
import CatalogCard from '../../components/CatalogCard/CatalogCard';
import { useAppSelector } from '../../store/hooks';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';

const Catalog: FC = () => {
    const { info, loading } = useAppSelector(state => state.info)

    useEffect(() => {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])
    return (
        <section className={s.catalog}>
            <Helmet>
                <meta property="og:title" content={`Каталог | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`Каталог | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/catalog`} />
                <title>
                    Каталог | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            <div className={s.bg_title}>
                <h1 className={`${s.title} title`}>Каталог</h1>
            </div>
            <span className={s.line}></span>
            <div className={`${s.wrapper} container`}>
                {
                    info && info?.catalogs.length > 0 &&
                    info?.catalogs.map(el => <CatalogCard key={el.id} data={el} />)
                }
            </div>
            <span className={s.line}></span>
        </section>
    );
};

export default Catalog;