import React, { MouseEventHandler, useEffect, useState } from 'react';
import Detail_Swiper from '../../components/Detail_Swiper/Detail_Swiper';
import s from './Detail.module.scss'
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Heart } from './../../assets/icon/heart.svg';
import { ReactComponent as Close } from './../../assets/icon/close.svg';
import Accordion from '../../components/Accordion/Accordion';
import { clearDetail, fetchByCatalogProductDetail } from '../../store/slices/productsSlice';
import { scrollToTop } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IProduct } from '../../store/types';
import { addToWhishlistReducer } from '../../store/slices/whishlistSlice';
import { getLS, setLS } from '../../LS';
import Courses from './Courses/Courses';
import Loader from '../../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Long_arrow } from './../../assets/icon/long_arrow.svg'

const Detail = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const [selected_color, setSelected_color] = useState('')
    const { loading, error, detail } = useAppSelector(state => state.products)

    const handleActive: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        addToWhishlist()
    };

    useEffect(() => {
        if (id) {
            scrollToTop()
            dispatch(fetchByCatalogProductDetail(Number(id)))
        }

        return () => {
            dispatch(clearDetail())
        }
    }, [dispatch, id])

    const { whishlist } = useAppSelector(state => state.whishlist)

    const addToWhishlist = () => {
        let all_products: IProduct[] = getLS('whishlist') || []
        const product = detail
        if (all_products) {
            let one_elem = all_products.find(elem => elem.id === detail?.id)
            if (one_elem) {
                all_products = all_products.filter(el => el.id !== one_elem?.id)
            } else {
                product && all_products.push(product)
            }
            setLS('whishlist', all_products)
            dispatch(addToWhishlistReducer(all_products))
        }
    }

    const addProductToSS = () => {
        const product = {
            product_id: detail?.id,
            product_price: detail?.price,
            image: detail?.image,
            title: detail?.title,
            total_quantity: 0,
            rows: [],
            name: '',
            contact_method: '',
        }
        sessionStorage.setItem('product', JSON.stringify(product))
    }

    return (
        <section>
            <Helmet>
                <meta property="og:title" content={`${detail?.title} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <meta name="twitter:title" content={`${detail?.title} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro`} />
                <link rel="canonical" href={`https://biorokg.com/detail-product/${detail?.id}/${detail?.title}`} />
                <title>
                    {`${detail ? detail?.title : ''}`} | Швейное производство в Кыргызстане | Одежда оптом и на заказ для СНГ Bioro
                </title>
            </Helmet>
            {loading && <Loader />}
            {
                detail &&
                <div className={`${s.wrapper} container`}>
                    <Detail_Swiper image={detail.image} data={detail.images} />
                    <div className={s.content_title}>
                        <span className={s.in_stock}>{detail?.in_stock ? 'В НАЛИЧИИ' : 'НЕТ В НАЛИЧИИ'}</span>
                        <h2 className={`${s.title} title`}>{detail?.title}</h2>
                        <p className={s.price}>от {detail?.price} сом</p>
                        <Courses price={detail.price} />
                        <div className={s.buttons}>
                            <Link className={`${s.button} button`} to="pre-order-product" onClick={addProductToSS}>ЗАКАЗАТЬ ДЛЯ СВОЕГО БРЕНДА<Long_arrow /></Link>
                            <button
                                title={whishlist?.find(el => el.id === detail.id) ? 'Убрать из Избранное' : 'Добавить в избранное'}
                                className={s.favorite} onClick={handleActive}>
                                {whishlist?.find(el => el.id === detail.id) ? <Close /> : <Heart />}
                            </button>
                        </div>
                        <Accordion title='Описание' description={detail?.description} />
                    </div>
                </div>
            }
        </section>
    );
};

export default Detail;
