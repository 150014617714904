import React, { FC, useEffect } from 'react';
import s from './SuccessModal.module.scss'
import { useAppDispatch } from '../../../store/hooks';
import { toggleFeedbackSuccess } from '../../../store/slices/infoSlice';

const SuccessModal: FC = () => {
    const disppatch = useAppDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
            disppatch(toggleFeedbackSuccess())
        }, 3000)

        return () => clearTimeout(timer)
    }, [])

    return (
        <div className={s.wrapper}>
            <p>Заявка на консультацию отправлена успешно! Ожидайте с вами свяжутся в ближайшее время!</p>
        </div>
    );
};

export default SuccessModal;