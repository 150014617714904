import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IProduct } from "../types"

type whishlistState = {
    whishlist: IProduct[]
    countInWhishlist: number
}

const initialState: whishlistState = {
    whishlist: [],
    countInWhishlist: 0,
}

const whishlistSlice = createSlice({
    name: 'whishlist',
    initialState,
    reducers: {
        addToWhishlistReducer(state, action: PayloadAction<IProduct[]>) {
            state.whishlist = action.payload
            state.countInWhishlist = action?.payload?.length
        }
    },
    extraReducers: ({ addCase }) => {

        // ===========================================================
    },
})

export const { addToWhishlistReducer } = whishlistSlice.actions

export default whishlistSlice.reducer