import React, { FC, useEffect } from 'react'
import s from './Success.module.scss'
import { ReactComponent as SuccessIcon } from './../../assets/icon/success.svg'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../../utils'
import { ReactComponent as Long_arrow } from './../../assets/icon/long_arrow.svg'

const Success: FC = () => {


    useEffect(() => {
        scrollToTop()
    }, [])
    return (
        <section className={s.wrapper}>
            <div className={s.container}>
                <SuccessIcon />
                <p>Ваш предзаказ оформлен.<br />
                    В ближайшее время с Вами свяжется менеджер.</p>
                <Link className={`${s.btn} button`} to='/'>НА ГЛАВНУЮ<Long_arrow /></Link>
            </div>
        </section>
    )
}

export default Success