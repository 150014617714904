import React, { useEffect } from 'react';
import './App.scss'
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import { useAppDispatch } from './store/hooks';
import { addToWhishlistReducer } from './store/slices/whishlistSlice';
import { getLS } from './LS';
import 'animate.css';
// import UnderConstraction from './UnderConstraction';

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const favorites = getLS('whishlist') || []
    if (favorites !== null || favorites !== undefined) {
      dispatch(addToWhishlistReducer(favorites))
    }
  }, [dispatch])
  return (
    <div>
      <Header />
      <Main />
      {/* <UnderConstraction /> */}
      <Footer />
    </div>
  );
};

export default App;