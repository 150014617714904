import React, { FC, useState } from 'react';
import s from './Collection.module.scss';
import catalog1 from '../../../assets/images/catalog/catalog1.png'
import catalog2 from '../../../assets/images/catalog/catalog2.png'
import Collection_Swiper from '../../../components/Collection_Swiper/Collection_Swiper';
import { useAppSelector } from '../../../store/hooks';

const Collection: FC = () => {
    const { info } = useAppSelector(state => state.info)
    return (
        <section>
            <div className={`${s.wrapper} container`}>
                {info && <Collection_Swiper title='Коллекции' data={info?.catalogs} />}
            </div>
        </section>
    );
}

export default Collection;
