import React, { FC } from 'react'
import s from './Reviews.module.scss'
import { ReactComponent as Quotes } from '../../../assets/icon/quotes.svg'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks'
import { ReactComponent as Long_arrow } from '../../../assets/icon/long_arrow.svg'
const Reviews: FC = () => {
    const { info } = useAppSelector(state => state.info)

    return (
        <section>
            <div className={`${s.wrapper} container`}>
                <h2 className='title'>Отзывы</h2>
                <div className={s.box}>
                    {info?.reviews.slice(0, 3).map((e) => (
                        <div key={e.id} className={s.card}>
                            <img src={e?.image} alt={`${e?.name}`} />
                            <h3>{e.name}</h3>
                            <div>
                                <Quotes width={85} />
                                <p>{e?.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={s.btn}>
                    <Link to='/reviews' className={`${s.button} button`}>СМОТРЕТЬ ВСЕ<Long_arrow /></Link>
                </div>
            </div>
        </section>
    )
}

export default Reviews